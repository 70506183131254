import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { slide as Menu } from "react-burger-menu"

import "@fortawesome/fontawesome-free/css/all.min.css"

import configs from "../../site-config"

const FAQPage = ({ data }) => (
  <Layout>
    <SEO title="About" keywords={configs.app_keywords} />
    <Menu right>
      <a id="home" className="menu-item" href="/">
        Home
      </a>
      <a id="faq" className="menu-item" href="/faq/">
        FAQ
      </a>
      <a id="privacy-policy" className="menu-item" href="/privacy">
        Privacy Policy
      </a>
      <a id="terms-and-conditions" className="menu-item" href="/terms">
        Terms and Conditions
      </a>
      <a id="tutorials" className="menu-item" href="/tutorials">
        Tutorials
      </a>
      <a id="about" className="menu-item" href="/about">
        About
      </a>
    </Menu>
    <div>
      <div className="container-terms">
        <header>
          <div className="logo">
            <div className="appIconShadow">
              <svg width="0" height="0">
                <defs>
                  <clipPath id="shape">
                    <path
                      id="shape"
                      d="M6181.23,233.709v-1.792c0-.5-0.02-1-0.02-1.523a24.257,24.257,0,0,0-.28-3.3,11.207,11.207,0,0,0-1.04-3.132,10.683,10.683,0,0,0-1.95-2.679,10.384,10.384,0,0,0-2.68-1.943,10.806,10.806,0,0,0-3.13-1.038,19.588,19.588,0,0,0-3.3-.285c-0.5-.017-1-0.017-1.52-0.017h-22.39c-0.51,0-1.01.017-1.53,0.017a24.041,24.041,0,0,0-3.3.285,11.009,11.009,0,0,0-3.13,1.038,10.491,10.491,0,0,0-4.62,4.622,10.893,10.893,0,0,0-1.04,3.132,19.2,19.2,0,0,0-.28,3.3c-0.02.5-.02,1-0.02,1.523v22.392c0,0.5.02,1,.02,1.524a24.257,24.257,0,0,0,.28,3.3,10.9,10.9,0,0,0,1.04,3.132,10.491,10.491,0,0,0,4.62,4.622,11.04,11.04,0,0,0,3.13,1.038,19.891,19.891,0,0,0,3.3.285c0.51,0.017,1.01.017,1.53,0.017h22.39c0.5,0,1-.017,1.52-0.017a24.221,24.221,0,0,0,3.3-.285,10.836,10.836,0,0,0,3.13-1.038,10.408,10.408,0,0,0,2.68-1.943,10.683,10.683,0,0,0,1.95-2.679,11.217,11.217,0,0,0,1.04-3.132,20.257,20.257,0,0,0,.28-3.3c0.02-.5.02-1,0.02-1.524v-20.6h0Z"
                      transform="translate(-6131 -218)"
                    />
                  </clipPath>
                </defs>
              </svg>
              <Img
                fluid={data.headerIcon.childImageSharp.fluid}
                className="headerIcon"
              />
            </div>
            <p
              className="headerName"
              style={{ color: configs.footer_text_color }}
            >
              {configs.app_name}
            </p>
          </div>
        </header>
        <div className="body">
          <h1>Advisory Panel</h1>
          <p>
            In landscape photography, location and timing are key. Researching
            and scouting locations is critical for any photographer to be
            successful in his art. We also understand that every photographer
            has some unique locations up his sleeve, and we respect that. Hence,
            we want to help you remember that location with all possible details
            along with a short note about your vision and composition. As
            photographers, we have a moral responsibility to keep every location
            safe and maintained for years to come. So, store as many unique
            locations in your bucket list as you want, with no public
            interference.
          </p>
          <p>
            PicnPlace has been developed by a group of techie friends taking
            valuable advice from photographer friends. Here is the advisory
            panel for the PicnPlace app.
          </p>
        </div>
        <div className="features">
          <div className="feature">
            <div>
              <span className="fa-stack fa-1x">
                <i className="iconBack fas fa-circle fa-stack-2x" />
                <i className="iconTop fas fa-user fa-stack-1x" />
              </span>
            </div>
            <div className="featureText">
              <Img
                fluid={data.dibyenduDas.childImageSharp.fixed}
                className="pfp"
              />
              <br />
              <h3>Dibyendu Das</h3>
              <p>
                In his own words - In essence, I am a landscape and travel
                photographer hailing from the picturesque wetlands of Barak
                Valley, Assam. I have been practicing photography for over ten
                years now and it is safe to say that I have a decent amount of
                grasp over it. I also have a knack in wildlife photography. I
                have quite a few accolades and publications bestowed upon me by
                various national and international associations and magazines
                respectively.
              </p>
            </div>
          </div>
          <div className="feature">
            <div>
              <span className="fa-stack fa-1x">
                <i className="iconBack fas fa-circle fa-stack-2x" />
                <i className="iconTop fas fa-user fa-stack-1x" />
              </span>
            </div>
            <div className="featureText">
              <Img
                fluid={data.saeeBhurke.childImageSharp.fixed}
                className="pfp"
              />
              <br />
              <h3>Saee Bhurke</h3>
              <p>
                Saee is a Professional Photographer and aspiring Filmmaker based
                in Pune, India. Saee is the founder, CEO & Lead Director of
                Photography of the digital content & media production company{" "}
                <em>The Keen Earthling Studio</em>, also based in Pune. She has
                been providing professional photography services since 2013 and
                also has mentored 500+ students in Travel & Nature photography
                for the last 10 years. Saee's photography work has been
                published on the covers of many reputed international magazines
                and has won national & international awards. Saee takes her
                inspiration for creating visual stories from her passion and
                love for Travel & Exploration. Saee as an artist is
                impassionated about Fine Art, Music & Food.
              </p>
            </div>
          </div>
          <div className="feature">
            <div>
              <span className="fa-stack fa-1x">
                <i className="iconBack fas fa-circle fa-stack-2x" />
                <i className="iconTop fas fa-user fa-stack-1x" />
              </span>
            </div>
            <div className="featureText">
              <Img
                fluid={data.sachinDeshpande.childImageSharp.fixed}
                className="pfp"
              />
              <br />
              <h3>Sachin Deshpande</h3>
              <p>
                Sachin is an IT consultant by day, and photographer by night—
                literally! He lives in the Bay Area, California, and especially
                loves to shoot landscapes, nightscapes, and architecture. As a
                kid, Sachin loved to sketch, but as he grew older, found he had
                a shrinking amount of time to pursue it and found photography,
                its digital cousin. Now, he is an award-winning photographer in
                his own right, and hopes to bring the furthest reaches of the
                universe closer to you all. Sachin also hosts photography
                workshops and loves to teach the craft to anyone who wants to
                learn.
              </p>
            </div>
          </div>
        </div>
        <footer>
          <div className="footerIcons">
            {configs.facebook_username && (
              <a
                href={`https://facebook.com/${configs.facebook_username}`}
                aria-label="Facebook"
              >
                <span className="fa-stack fa-1x">
                  <i className="socialIconBack fas fa-circle fa-stack-2x" />
                  <i className="socialIconTop fab fa-facebook fa-stack-1x" />
                </span>
              </a>
            )}

            {configs.linkedin_username && (
              <a
                href={`https://www.linkedin.com/in/${configs.linkedin_username}`}
                aria-label="LinkedIn"
              >
                <span className="fa-stack fa-1x">
                  <i className="socialIconBack fas fa-circle fa-stack-2x" />
                  <i className="socialIconTop fab fa-linkedin fa-stack-1x" />
                </span>
              </a>
            )}

            {configs.twitter_username && (
              <a
                href={`https://twitter.com/${configs.twitter_username}`}
                aria-label="Twitter"
              >
                <span className="fa-stack fa-1x">
                  <i className="socialIconBack fas fa-circle fa-stack-2x" />
                  <i className="socialIconTop fab fa-twitter fa-stack-1x" />
                </span>
              </a>
            )}

            {configs.github_username && (
              <a
                href={`https://github.com/${configs.github_username}`}
                aria-label="GitHub"
              >
                <span className="fa-stack fa-1x">
                  <i className="socialIconBack fas fa-circle fa-stack-2x" />
                  <i className="socialIconTop fab fa-github fa-stack-1x" />
                </span>
              </a>
            )}

            {configs.email_address && (
              <a href={`mailto:${configs.email_address}`} aria-label="Email">
                <span className="fa-stack fa-1x">
                  <i className="socialIconBack fas fa-circle fa-stack-2x" />
                  <i className="socialIconTop fas fa-envelope fa-stack-1x" />
                </span>
              </a>
            )}
          </div>
          <p className="footerText">
            built by{" "}
            {configs.your_link ? (
              <a href={configs.your_link}>{configs.your_name}</a>
            ) : (
              `${configs.your_name}`
            )}{" "}
            for picnplace, inc
            {configs.your_city && ` in ${configs.your_city}`}
          </p>
        </footer>
        {/*TODO: Add App Store API */}
      </div>
    </div>
  </Layout>
)

export default FAQPage

export const query = graphql`
  query {
    headerIcon: file(relativePath: { eq: "icon.png" }) {
      childImageSharp {
        fluid(maxWidth: 50) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    sachinDeshpande: file(relativePath: { eq: "sachinDeshpande.jpg" }) {
      childImageSharp {
        fixed(height: 250) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    dibyenduDas: file(relativePath: { eq: "dibyenduDas.jpg" }) {
      childImageSharp {
        fixed(height: 250) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    saeeBhurke: file(relativePath: { eq: "saeeBhurke.jpg" }) {
      childImageSharp {
        fixed(height: 75) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    appStore: file(relativePath: { eq: "appstore.png" }) {
      childImageSharp {
        fixed(width: 220) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    playStore: file(relativePath: { eq: "playstore.png" }) {
      childImageSharp {
        fixed(height: 75) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    iphoneScreen: file(relativePath: { glob: "screenshot/*.{png,jpg}" }) {
      childImageSharp {
        fluid(maxWidth: 350) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    videoScreen: file(
      extension: { ne: "txt" }
      relativePath: { glob: "videos/*" }
    ) {
      publicURL
      extension
    }
    appIconLarge: file(relativePath: { eq: "icon.png" }) {
      childImageSharp {
        fluid(maxWidth: 120) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    headerImage: file(relativePath: { eq: "headerimage.jpg" }) {
      childImageSharp {
        fluid(maxHeight: 714) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    iphonePreviewBlack: file(relativePath: { eq: "black.png" }) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    iphonePreviewBlue: file(relativePath: { eq: "blue.png" }) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    iphonePreviewCoral: file(relativePath: { eq: "coral.png" }) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    iphonePreviewWhite: file(relativePath: { eq: "white.png" }) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    iphonePreviewYellow: file(relativePath: { eq: "yellow.png" }) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
